import React, {FC} from 'react';
import classes from './Footer.module.css'

const Footer = props => {
    return (
        <div className={[props.className, classes.footer].join(' ')}>
            {props.children}
        </div>
    );
};

export default Footer;