import React, {FC} from 'react';
import classes from './VCMLink.module.css'

const VCMLink= props => {
    return (
        <a
            href='https://zavod-vcm.ru'
            className={classes.link}
        >
            ОАО «Волгоцеммаш»
        </a>
    );
};

export default VCMLink;