import React, {FC} from 'react';
import classes from './Info.module.css'
import Text from "../Text/Text.jsx";

const Info = props => {
    return (
        <div className={[props.className, classes.info].join(' ')}>
            <Text>
                Тел.: +7 (848) 260-22-48
            </Text> 
            <Text>
                E-mail: thvtm@thvtm.ru
            </Text> 
            <Text>
                Адрес: 445007, САМАРСКАЯ ОБЛАСТЬ, Г.О. ТОЛЬЯТТИ, Г ТОЛЬЯТТИ, УЛ НОВОЗАВОДСКАЯ, ВЛД. 12, СТР. 126.
            </Text>
            <Text>
                ИНН/КПП: 6382096937/638201001
            </Text>         
            <Text>
                Расчетный счет: 40702810900004070209 в АО «Тольяттихимбанк»
            </Text> 
            <Text>
                БИК: 043678838 
            </Text> 
            <Text>
                Корр.счёт: 30101810000000000838
            </Text> 
        </div>
    )
}

export default Info;