import React, {FC, useEffect, useState} from 'react';
import classes from './BGColor.module.css'

const BGColor = props => {
    const [op, setOp] = useState(0)

    useEffect(() => {
        const handleScroll = (e) => {
            const a = e.currentTarget.scrollY / 800 + 0.5 < 1 ? e.currentTarget.scrollY / 800 + 0.5 : 1
            setOp(a - 0.4)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [window.scrollY])

    return (
        <div
            className={[props.className, classes.bGColor].join(' ')}
            style={{opacity: `${op}`}}
        />
    );
};

export default BGColor;