import React from 'react';
import classes from './Line.module.css'

const Line = props => {
    return (
        <div className={[props.className, classes.line].join(' ')}>
            {props.children}
        </div>
    );
};

export default Line;