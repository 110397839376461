import './App.css';
import {Routes, Route, Navigate} from 'react-router-dom';
import Header from './components/Header/Header.jsx';
import Footer from './components/Footer/Footer.jsx';
import MainPage from './Pages/MainPage/MainPage.jsx';
import FixedBG from './components/FixedBG/FixedBG';
import BGColor from './components/BGColor/BGColor';

function App() {
    return (
        <div
            className="App"
        >
            <Header className='App-Header'/>
            <div
                className='App-Body'
            >
                <Routes>
                    <Route path="*" element={<MainPage className={'Page'} />}/>
                </Routes>
                <Footer />
            </div>
            <FixedBG/>
            <BGColor/>
        </div>
    );
}

export default App;
