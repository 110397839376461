import React from 'react';
import classes from './Header.module.css'

const HeaderButton = props => {
    const goTo = () => {
        if (props.id) {
            window.location.hash = "#" + props.id
        }
    }

    return (
        <div
            className={[props.className, classes.headerButton].join(' ')}
            onClick={goTo}
        >
            {props.children}
        </div>
    );
};

export default HeaderButton;