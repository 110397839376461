import React, {FC} from 'react';
import classes from './FixedBG.module.css'

const FixedBG = props => {
    return (
        <div className={classes.wr}>
            <img className={[props.className, classes.fixedBG].join(' ')} src='./img/bg_photo.webp'/>
        </div>
    );
};

export default FixedBG;