import React from 'react';
import classes from './LogoBox.module.css'

const LogoBox = props => {
    
    return (
        <div
            onClick={props.onClick}
            className={[props.className, classes.logoBox].join(' ')}
        >
            <img className={classes.logo} src="./img/logo.svg" alt="" />
            <div className={classes.logoOAOText}>общество с ограниченной ответственностью</div>
            <div className={classes.logoText}>ТД ВОЛГОТЯЖМАШ</div>
        </div>
    );
};

export default LogoBox;