import React, {FC} from 'react';
import classes from './MainTitle.module.css'

const MainTitle = props => {
    return (
        <div className={[props.className, classes.titleBox].join(' ')}>
            <h1 className={classes.mainTitle}>
                <p>
                    ООО ТОРГОВЫЙ ДОМ
                </p>
                <p>
                    «ВОЛГОТЯЖМАШ»
                </p>
            </h1>
            <h2 className={classes.secondTitle}>
                <p>
                    Крупное оборудование
                </p>
                <p>
                    для крупного бизнеса
                </p>
            </h2>
            <img className={classes.gear} src="./img/gear.svg" alt="" />
        </div>
    );
};

export default MainTitle;