import React from 'react';
import classes from './Header.module.css'
import LogoBox from '../logoBox/LogoBox';
import HeaderButton from './HeaderButton/HeaderButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEnvelope,
    faPhone
} from '@fortawesome/free-solid-svg-icons'

const Header = props => {
    return (
        <div className={[props.className, classes.header].join(' ')}>
            <div className={classes.logoAndLinks}>
                <LogoBox
                    onClick={() => {window.location.hash = ''}}
                />
                <div className={classes.buttons}>
                    <a href='https://zavod-vcm.ru'>
                        <HeaderButton>Волгоцеммаш</HeaderButton>
                    </a>
                    <HeaderButton id={'about'}>О нас</HeaderButton>
                    <HeaderButton id={'cert'}>Сертификаты</HeaderButton>
                    <HeaderButton id={'contacts'}>Контакты</HeaderButton>
                </div>
            </div>
            <div className={classes.contacts}>
                <a
                    href='tel:+78482602248' 
                    className={classes.tel}
                >
                    <FontAwesomeIcon
                        className={classes.icon}
                        icon={faPhone}
                    />
                    +7 (848) 260-22-48
                </a>
                <a
                    href='mailto:thvtm@thvtm.ru' 
                    className={classes.tel}
                >
                    <FontAwesomeIcon
                        className={classes.icon}
                        icon={faEnvelope}
                    />
                    thvtm@thvtm.ru
                </a>
            </div>
        </div>
    );
};

export default Header;