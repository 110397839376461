import React from 'react';
import classes from './MainPage.module.css'
import Title from '../../components/Title/Title.jsx'
import Line from '../../components/Line/Line.jsx'
import SecondTitle from '../../components/SecondTitle/SecondTitle.jsx'
import Text from '../../components/Text/Text.jsx'
import MainTitle from '../../components/MainTitle/MainTitle';
import Cert from '../../components/Cert/Cert';
import Info from '../../components/Info/Info';
import VCMLink from '../../components/VCMLink/VCMLink';

const MainPage = props => {

    return (
        <div
            className={[props.className, classes.main].join(' ')}
        >
            <MainTitle/>
            <div/>
            <div/>
            <div/>
            <Line/>
            <div/>
            <div/>
            <div/>
            <SecondTitle id={'about'}>
               О нас
            </SecondTitle>
            <div className={classes.about}>
                <Text>
                    ООО ТД «Волготяжмаш» является официальным дилером <VCMLink/> и имеет все необходимые полномочия на предложение и поставку оборудования, изготовляемая <VCMLink/>
                </Text>
                <Text>
                    В связи с заключением 31.05.2023 года эксклюзивного дилерского договора  между <VCMLink/> и ООО Торговый дом «Волготяжмаш», предлагаем Вам рассмотреть возможность заключения договора с ООО Торговый дом «Волготяжмаш»
                </Text>
                <Text>
                    Согласно условиям вышеуказанного договора продукция, изготовляемая <VCMLink/> будет реализовываться исключительно через ООО Торговый дом «Волготяжмаш»
                </Text>
            </div>
            <SecondTitle id={'cert'}>
               Сертификаты
            </SecondTitle>
            <Cert/>
            <SecondTitle id={'contacts'}>
               Контактная информация
            </SecondTitle>
            <Info/>
        </div>
    );
};

export default MainPage;