import React from 'react';
import classes from './Text.module.css'

const Text = props => {

    const textClass = props.lightFont ? [classes.lightText] : [classes.text]

    return (
        <h2
            className={[props.className, ...textClass].join(' ')}
            style={{fontSize: `${props.fontSize ? props.fontSize : '25px'}`, lineHeight: `${props.lineHeight ? props.lineHeight : '30px'}`}}
            onClick={() => {if (props.onClick) props.onClick()}}
        >
            {props.children}
        </h2>
    );
};

export default Text;