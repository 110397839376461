import React, {FC} from 'react';
import classes from './Cert.module.css'
import Text from '../../components/Text/Text.jsx'

const Cert = props => {
    return (
        <div className={[props.className, classes.certBox].join(' ')}>
            <div className={classes.certContainer}>
                <img className={classes.cert} src="./img/diler.png" alt="сертификат" />
                <div>
                    <Text>Письмо о дилерстве</Text>
                </div>
                <div>
                    <a href="./img/diler.png" download>
                        <Text
                            className={classes.download}
                            fontSize={'20px'}
                        >
                            Скачать
                        </Text>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Cert;