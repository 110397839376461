import React from 'react';
import classes from './SecondTitle.module.css'
import Text from '../Text/Text'

const SecondTitle = props => {
    return (
        <div
            className={[props.className, classes.title].join(' ')}
            id={props.id}
        >
            <Text lightFont={true} fontSize={'70px'} lineHeight={'50px'}>{props.children}</Text>
        </div>
    );
};

export default SecondTitle;